import Mock from '../mock';

const database = {
  portfolios: [
    {
      id: 1,
      title: 'devSpace',
      subtitle: 'Social Media App',
      imageUrl: '/images/devspace.png',
      largeImageUrl: [
        '/images/devspace-lg1.png',
        '/images/devspace-lg2.png',
        '/images/devspace-lg3.png',
        '/images/devspace-lg4.png',
      ],
      url: 'https://dev-space.herokuapp.com/',
      demo: 'https://youtu.be/gYWNbK39sU8',
    },
    {
      id: 2,
      title: 'Jira Lite',
      subtitle: 'Project Management App',
      imageUrl: '/images/jira-lite.png',
      largeImageUrl: [
        '/images/jira-lite-lg1.png',
        '/images/jira-lite-lg2.png',
        '/images/jira-lite-lg3.png',
        '/images/jira-lite-lg4.png',
      ],
      url: 'https://jira-lite-ts-react.netlify.app/',
      demo: 'https://youtu.be/6oIF0JGzpEA',
    },
    {
      id: 3,
      title: 'Pet Foodie',
      subtitle: 'E-commerce Web App',
      imageUrl: '/images/pet-foodie.png',
      largeImageUrl: [
        '/images/pet-foodie-lg1.png',
        '/images/pet-foodie-lg2.png',
        '/images/pet-foodie-lg3.png',
        '/images/pet-foodie-lg4.png',
        '/images/pet-foodie-lg5.png',
      ],
      url: 'https://grace-shopper-pet-foodie.herokuapp.com/',
      demo: 'https://youtu.be/vSJY5z1-e10',
    },
  ],
  miniApps: [
    {
      id: 1,
      title: 'Campus Mgmt REST API',
      imageUrl: '/images/campus-student.png',
      url: 'https://nh-jpfp-campus-student.herokuapp.com/#/',
    },
    {
      id: 2,
      title: 'Cocktail DB',
      imageUrl: '/images/cocktail-db.png',
      url: 'https://cocktail-db-nh.netlify.app',
    },
    {
      id: 3,
      title: 'Rapid API Tutorial',
      imageUrl: '/images/rapid-api.png',
      url: 'https://rapid-api-examples-react-nh.netlify.app/#/shazam/app',
    },
    {
      id: 4,
      title: 'Markdown Preview',
      imageUrl: '/images/markdown-preview.png',
      url: 'https://markdown-preview-nh.netlify.app/',
    },

    {
      id: 5,
      title: 'Pixlate Drawing',
      imageUrl: '/images/pixlate-drawing.png',
      url: 'https://pixlate-drawing-nh.netlify.app/',
    },
    {
      id: 6,
      title: 'Stock Photos Infinite Scroll',
      imageUrl: '/images/stock-photos-infinite-scroll.png',
      url: 'https://stock-photos-infinite-scroll-nh.netlify.app/',
    },
    {
      id: 7,
      title: 'Color Generator',
      imageUrl: '/images/color-generator.png',
      url: 'https://color-generator-nh.netlify.app',
    },
    {
      id: 8,
      title: 'Quiz Form',
      imageUrl: '/images/quiz-form.png',
      url: 'https://quiz-form-nh.netlify.app',
    },

    {
      id: 9,
      title: 'PainQx',
      imageUrl: '/images/painqx.png',
      url: 'https://painqx.com',
    },

    {
      id: 10,
      title: 'Cascade Prodrug',
      imageUrl: '/images/cascade-prodrug.png',
      url: 'https://www.cascadeprodrug.com',
    },
    {
      id: 11,
      title: 'NuMedii',
      imageUrl: '/images/numedii.png',
      url: 'https://numedii.com',
    },
    {
      id: 12,
      title: 'Food Menu',
      imageUrl: '/images/food-menu.png',
      url: 'https://food-menu-nh.netlify.app',
    },
    {
      id: 13,
      title: 'Tour Listing',
      imageUrl: '/images/tour-listing.png',
      url: 'https://tour-listing-nh.netlify.app',
    },
    {
      id: 14,
      title: 'Birthday Reminder',
      imageUrl: '/images/birthday-reminder.png',
      url: 'https://birthday-reminder-nh.netlify.app',
    },
    {
      id: 15,
      title: 'Random Person',
      imageUrl: '/images/random-person.png',
      url: 'https://random-person-nh.netlify.app/',
    },

    {
      id: 16,
      title: 'Stripe Sub-Menu',
      imageUrl: '/images/stripe-submenu.png',
      url: 'https://stripe-submenu-nh.netlify.app',
    },
    {
      id: 17,
      title: 'Follower Pagination',
      imageUrl: '/images/follower-pagination.png',
      url: 'https://follower-pagination-nh.netlify.app',
    },
    {
      id: 18,
      title: 'Accordion FAQ',
      imageUrl: '/images/accordion-faq.png',
      url: 'https://accordion-faq-nh.netlify.app',
    },
    {
      id: 19,
      title: 'Movie DB',
      imageUrl: '/images/movie-db.png',
      url: 'https://movie-db-nh.netlify.app',
    },
    {
      id: 20,
      title: 'Grocery Bud',
      imageUrl: '/images/grocery-bud.png',
      url: 'https://grocery-bud-nh.netlify.app',
    },
    {
      id: 21,
      title: 'lorem ipsum Generator',
      imageUrl: '/images/lorem-ipsum-generator.png',
      url: 'https://lorem-ipsum-generator-nh.netlify.app',
    },
    {
      id: 22,
      title: 'Review Slider',
      imageUrl: '/images/review-slider.png',
      url: 'https://review-slider-2-nh.netlify.app',
    },
    {
      id: 23,
      title: 'Dark Mode Toggle',
      imageUrl: '/images/dark-mode-toggle.png',
      url: 'https://dark-mode-toggle-nh.netlify.app',
    },
    {
      id: 24,
      title: 'Hacker News',
      imageUrl: '/images/hacker-news.png',
      url: 'https://hacker-news-nh.netlify.app/',
    },
    {
      id: 25,
      title: 'useReducer Cart',
      imageUrl: '/images/usereducer-cart.png',
      url: 'https://usereducer-cart-nh.netlify.app/',
    },

    {
      id: 26,
      title: 'Resume Tabs',
      imageUrl: '/images/resume-tabs.png',
      url: 'https://resume-tabs-nh.netlify.app',
    },

    {
      id: 27,
      title: 'IMDb Movie Reviews',
      imageUrl: '/images/imdb-movies.png',
      url: 'https://nh-movie-restful-api.herokuapp.com/movies',
    },
  ],
  information: {
    name: 'Nono Hu',
    aboutContent:
      'I am a fullstack developer and software engineer. I enjoy developing both the front-end and back-end of web applications including configuring servers, building databases, implementing REST APIs, and designing user interfaces.',
    age: 32,
    phone: '',
    nationality: '',
    language: '',
    email: 'swenonohu@gmail.com',
    address: '',
    freelanceStatus: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/nonohu/',
      dribbble: '',
      github: 'https://github.com/xyshtd',
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/empty.pdf',
  },
  skills: {
    frontend: ['JavaScript', 'TypeScript', 'React', 'Redux', 'HTML', 'CSS'],
    backend: ['Node.js', 'Express', 'PHP'],
    databases: ['MySQL', 'PostgreSQL', 'Redis'],
    tools: [
      'Git',
      'Sequelize',
      'Jest',
      'Mocha',
      'Chai',
      'Supertest',
      'Travis CI',
      'Heroku',
      'Postman',
      'Bcrypt',
      'JSON Web Tokens',
      'Socket.IO',
      'Webpack',
      'Babel',
      'Material UI',
      'Bootstrap',
    ],
  },
};

Mock.onGet('/api/information').reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet('/api/skills').reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet('/api/portfolios').reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet('/api/experience').reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet('/api/miniapps').reply((config) => {
  const response = database.miniApps;
  return [200, response];
});
