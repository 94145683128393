import React from 'react';
import MiniApp from './MiniApp';

function MiniappsView({ miniapps }) {
  return (
    <div className='row mt-30-reverse'>
      {miniapps.map((miniapp) => (
        <div className='col-lg-4 col-md-6 col-12 mt-30' key={miniapp.id}>
          <MiniApp content={miniapp} />
        </div>
      ))}
    </div>
  );
}

export default MiniappsView;
