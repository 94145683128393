import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LineIcon from 'react-lineicons';
import ProgressiveImage from 'react-progressive-image';
import { Link, NavLink } from 'react-router-dom';
import * as Icon from 'react-feather';
function Header() {
  const [information, setInformation] = useState('');
  const [navigationToggler, setNavigationToggler] = useState(false);

  const handleNavigationToggler = () => {
    setNavigationToggler(!navigationToggler);
  };

  useEffect(() => {
    axios.get('/api/information').then((response) => {
      setInformation(response.data);
    });
  }, []);

  return (
    <nav className={navigationToggler ? 'mi-header is-visible' : 'mi-header'}>
      <button onClick={handleNavigationToggler} className='mi-header-toggler'>
        {!navigationToggler ? (
          <LineIcon name='menu' />
        ) : (
          <LineIcon name='close' />
        )}
      </button>
      <div className='mi-header-inner'>
        <div className='mi-header-image'>
          <Link to='/'>
            <ProgressiveImage
              src={information.brandImage}
              placeholder='/images/about-image-placeholder.png'
            >
              {(src) => <img src={src} alt='brandimage' />}
            </ProgressiveImage>
          </Link>
        </div>

        <ul className='mi-header-menu'>
          <li>
            <NavLink to='/' end>
              <span>About</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/skills'>
              <span>Skills</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/portfolios'>
              <span>Portfolios</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/miniapps'>
              <span>Mini Apps</span>
            </NavLink>
          </li>
        </ul>
        <p className='mi-header-copyright'>
          <Icon.Mail />{' '}
          <a href='mailto:swenonohu@gmail.com'>swenonohu@gmail.com</a>
        </p>
      </div>
    </nav>
  );
}

export default Header;
