import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

import Sectiontitle from '../components/Sectiontitle';
import Smalltitle from '../components/Smalltitle';
import Spinner from '../components/Spinner';

function Skills() {
  const [skills, setSkills] = useState([]);
  console.log(skills.frontend);
  useEffect(() => {
    axios.get('/api/skills').then((response) => {
      setSkills(response.data);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Nono Hu - Skills</title>
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className='mi-skills-area mi-section  mi-padding-top mi-padding-bottom'>
          <div className='container'>
            <Sectiontitle title='My Skills' />
            <Smalltitle title='Front-End' icon='website' />
            <div className='mi-skills'>
              <div className='row mt-30-reverse'>
                {skills.frontend?.map((skill) => (
                  <div once className='col-lg-6 mt-30' key={skill}>
                    <h5 className='mi-progress-title'>{skill}</h5>
                  </div>
                ))}
              </div>
            </div>
            <Smalltitle title='Back-End' icon='code-alt' />
            <div className='mi-skills'>
              <div className='row mt-30-reverse'>
                {skills.backend?.map((skill) => (
                  <div once className='col-lg-6 mt-30' key={skill}>
                    <h5 className='mi-progress-title'>{skill}</h5>
                  </div>
                ))}
              </div>
            </div>
            <Smalltitle title='Databases' icon='database' />
            <div className='mi-skills'>
              <div className='row mt-30-reverse'>
                {skills.databases?.map((skill) => (
                  <div once className='col-lg-6 mt-30' key={skill}>
                    <h5 className='mi-progress-title'>{skill}</h5>
                  </div>
                ))}
              </div>
            </div>
            <Smalltitle title='Tools/Libraries' icon='cog' />
            <div className='mi-skills'>
              <div className='row mt-30-reverse'>
                {skills.tools?.map((skill) => (
                  <div once className='col-lg-6 mt-30' key={skill}>
                    <h5 className='mi-progress-title'>{skill}</h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default Skills;
