import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './pages/Home';
import Notfound from './pages/Notfound';
import Portfolios from './pages/Portfolios';
import MiniApps from './pages/MiniApps';
import Skills from './pages/Skills';

function App() {
  const [lightMode, setLightMode] = useState(false);

  lightMode
    ? document.body.classList.add('light')
    : document.body.classList.remove('light');

  const handleMode = () => {
    if (!lightMode) {
      setLightMode(true);
      document.body.classList.add('light');
    } else {
      setLightMode(false);
      document.body.classList.remove('light');
    }
  };

  return (
    <BrowserRouter>
      <div className='light-mode'>
        <span className='icon'>
          <Icon.Sun />
        </span>
        <button
          className={
            lightMode ? 'light-mode-switch active' : 'light-mode-switch'
          }
          onClick={() => handleMode()}
        ></button>
      </div>
      <Routes>
        <Route path='/' index element={<Home lightMode={lightMode} />} />
        <Route path='skills' element={<Skills />} />
        <Route path='portfolios' element={<Portfolios />} />
        <Route path='miniapps' element={<MiniApps />} />
        <Route path='*' element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
