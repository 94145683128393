import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Pagination from '../components/Pagination';
import MiniappsView from '../components/MiniappsView';
import Sectiontitle from '../components/Sectiontitle';
import Spinner from '../components/Spinner';

function MiniApps() {
  const [miniapps, setMiniapps] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [portfoliosPerPage] = useState(9);

  useEffect(() => {
    let mounted = true;
    axios.get('/api/miniapps').then((response) => {
      if (mounted) {
        setMiniapps(response.data);
      }
    });
    return () => (mounted = false);
  }, []);

  const indexOfLastPortfolios = currentPage * portfoliosPerPage;
  const indexOfFirstPortfolios = indexOfLastPortfolios - portfoliosPerPage;
  const currentPortfolios = miniapps.slice(
    indexOfFirstPortfolios,
    indexOfLastPortfolios
  );

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <Helmet>
        <title>Nono Hu - MiniApps</title>
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className='mi-about mi-section mi-padding-top mi-padding-bottom'>
          <div className='container'>
            <Sectiontitle title='Mini Apps' />
            {<MiniappsView miniapps={currentPortfolios} />}
            {!(miniapps.length > portfoliosPerPage) ? null : (
              <Pagination
                className='mt-50'
                itemsPerPage={portfoliosPerPage}
                totalItems={miniapps.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default MiniApps;
