import React from 'react';
import * as Icon from 'react-feather';
import ProgressiveImage from 'react-progressive-image';

function MiniApp(props) {
  const { title, imageUrl, url } = props.content;

  const handleToggler = (value) => {
    setToggler(value);
  };

  return (
    <div className='mi-portfolio mi-portfolio-visible'>
      <div className='mi-portfolio-image'>
        <ProgressiveImage
          src={imageUrl}
          placeholder='/images/portfolio-image-placeholder.png'
        >
          {(src) => <img src={src} alt={title} />}
        </ProgressiveImage>
        <ul>
          {url ? (
            <li>
              <a rel='noopener noreferrer' target='_blank' href={url}>
                <Icon.Link />
              </a>
            </li>
          ) : null}
        </ul>
      </div>
      {!url ? (
        <h5>{title}</h5>
      ) : (
        <h5>
          <a rel='noopener noreferrer' target='_blank' href={url}>
            {title}
          </a>
        </h5>
      )}
    </div>
  );
}

export default MiniApp;
