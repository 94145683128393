import React from 'react';

function BackgroundLines(props) {
  //<div className="mi-bglines">
  return (
    <div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default BackgroundLines;
