import React, { Suspense, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Particle from '../components/Particle';
import Socialicons from '../components/Socialicons';
import Spinner from '../components/Spinner';

function Home({ lightMode }) {
  const [information, setInformation] = useState('');

  return (
    <Layout>
      <Helmet>
        <title>Nono Hu</title>
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className='mi-home-area mi-padding-section'>
          <Particle lightMode={lightMode} />
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-10 col-12'>
                <div className='mi-home-content'>
                  <h1>
                    Hi, I am <span className='color-theme'>Nono Hu</span>
                  </h1>
                  <p>
                    I am a fullstack developer and software engineer. I 💙
                    coding!
                    <br />I enjoy developing both the front-end and back-end of
                    web applications including configuring servers, building
                    databases, implementing REST APIs, and designing user
                    interfaces.
                  </p>
                  <Socialicons bordered />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default Home;
