import React from 'react';
import LineIcon from 'react-lineicons';

function Smalltitle(props) {
  return (
    <div
      className='mi-smalltitle'
      style={{ marginBottom: '1rem', marginTop: '2rem' }}
    >
      <span className='mi-smalltitle-icon'>
        <LineIcon name={props.icon} style={{ color: '#037fff' }} />
      </span>
      <h4 style={{ color: '#037fff' }}>{props.title}</h4>
    </div>
  );
}

export default Smalltitle;
